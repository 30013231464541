// Workaround for datetime picker
.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de;
}

.mat-calendar-body-cell:hover {
  background-color: $primary-color;
  color: #fff;
  border-radius: 999px;
}

.mat-calendar-body-selected {
  background-color: $primary-color;
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-disabled {
  color: $grey-3;

  &:hover {
    background-color: unset;
    color: $grey-3;
  }
}
