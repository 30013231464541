@import '../../vars.scss';

.demo {
  border-radius: 11px;
  padding: 2px 7px;
  font-family: 'Robbert-Medium', sans-serif;
  font-size: 13px;
  background: $border-color;
  color: white;
}

.mat-button-toggle-group.custom-button-toggle-group {
  --mat-standard-button-toggle-height: 40px;

  border-radius: 10px;
  border: 1px solid $border-default;
  background: white;
  padding: 5px;

  .mat-button-toggle {
    flex: 1;
    border-radius: 7px;
    background: white;
    border: none !important;
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    color: $text-grey-3;

    &:hover {
      background: #f6f7fa;
      color: $text-grey-2;

      .demo {
        background: $text-grey-3;
      }
    }

    &-checked {
      font-family: 'Roobert-SemiBold', sans-serif;
      border: 1px solid rgba(80, 59, 255, 0.1);
      background: radial-gradient(70.46% 150% at 50% 91.86%, rgba(80, 59, 255, 0.3) 0%, rgba(190, 183, 255, 0.3) 100%);
      color: $primary-color;

      .demo {
        background: $primary-color;
      }

      &:hover {
        font-family: 'Roobert-SemiBold', sans-serif;
        border: 1px solid rgba(80, 59, 255, 0.1);
        background: radial-gradient(
          70.46% 150% at 50% 91.86%,
          rgba(80, 59, 255, 0.3) 0%,
          rgba(190, 183, 255, 0.3) 100%
        );
        color: $primary-color;

        .demo {
          background: $primary-color;
        }
      }
    }

    .mat-button-toggle-label-content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
    }
  }
}

.custom-button-toggle-group-guide {
  --mat-standard-button-toggle-height: 33px !important;
  --mat-standard-button-toggle-shape: 6px;

  background: $background-grey-3;
  padding: 4px;
  height: 43px;

  .mat-button-toggle {
    flex: 1;
    border-radius: 4px;
    background: transparent;
    border: none !important;
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    color: $text-grey-2;

    &:hover {
      background: #f6f7fa;
    }

    &-checked {
      font-family: 'Roobert-SemiBold', sans-serif;
      background-color: white !important;
      color: $text-grey-2 !important;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }

    &-disabled {
      background-color: transparent !important;
    }
  }
}
