@import '../../vars.scss';

.mdc-button {
  --mat-mdc-button-persistent-ripple-color: white !important;

  padding: 14px 20px;
  min-width: 0 !important;

  &.large {
    padding: 14px 50px;
  }

  // Bulk component
  &.custom-button-bulk-toolbar {
    border-radius: 7px;
    .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $grey-2;
    }
  }

  // Primary
  &.mat-primary {
    border-radius: 7px;
    border: 1px solid #3b24fc;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
    }

    &:hover {
      border: 1px solid #1400bd;
      background: radial-gradient(135.14% 135.14% at 50% 0%, #503bff 0%, #2b14e8 100%);
    }

    &:focus {
      border: 1px solid #1400bd;
      background: radial-gradient(135.14% 135.14% at 50% 0%, #503bff 0%, #2b14e8 100%);
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      border: 1px solid #c9cad4;
      background: $background-grey-2;
      color: $grey-3;
    }
  }

  // Orange
  &.mat-orange {
    border-radius: 47px;
    border: 1px solid #ff5300;
    background: #ff5300 !important;
    color: white !important;
  }

  // Primary
  &.mat-primary-revert {
    border-radius: 7px;
    border: 1px solid white;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
      color: $primary-color;
    }

    &:disabled {
      border: 1px solid #c9cad4;
      background: $background-grey-2;
      color: $grey-3;
    }
  }

  &.mat-black {
    border-radius: 47px;
    border: 1px solid black;
    background: black !important;
    color: white !important;
  }

  // Primary flash
  &.mat-primary-flash {
    border-radius: 7px;
    border: 1px solid $primary-color;
    background: radial-gradient(50% 100% at 50% 100%, #9453ff 0%, $primary-color 100%);
    box-shadow: 0px 1px 5px 0px rgba(80, 59, 255, 0.2);
    color: white !important;
  }

  // Warn
  &.mat-warn {
    border-radius: 7px;
    border: 1px solid #d30101;

    &:hover {
      border: 1px solid #930000;
      background: radial-gradient(121.62% 121.62% at 50% 0%, #ef2e39 0%, #d11823 100%);
    }

    &:focus {
      border: 1px solid #930000;
      background: radial-gradient(121.62% 121.62% at 50% 0%, #ef2e39 0%, #d11823 100%);
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      color: white;
      background: #ef2e39;
    }
  }

  // Secondary
  &.mat-secondary {
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white;
    }
  }

  &.mat-secondary-menu {
    height: 38px;
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;
      font-family: 'Roobert-Medium', sans-serif;
      font-size: 14px;
      color: $grey-1;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white;
    }
  }

  // Secondary warn
  &.mat-secondary-warn {
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      color: $warn-color;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white;
    }
  }

  // Secondary Round
  &.mat-secondary-round {
    border-radius: 37px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 2px;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white !important;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white !important;
    }
  }

  // Secondary Flash
  &.mat-secondary-flash {
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;
    color: $primary-color !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white !important;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white !important;
    }
  }
  // Secondary Flash Round
  &.mat-secondary-flash-round {
    border-radius: 24px;
    border: 1px solid $primary-color;
    background: white !important;
    color: $primary-color !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
    }

    &:hover {
      border: 1px solid $primary-color;
      background: white !important;
    }
  }

  // Sync
  &.mat-sync {
    border-radius: 7px;
    background: #0052ff !important;
    color: white !important;

    &:hover {
      background: #0042ce;
    }

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 6px;
    }
  }
}
