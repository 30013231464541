@import '../../vars.scss';

.mat-mdc-tab-group.custom-tab-group {
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-inactive-ripple-color: transparent;

  .mat-mdc-tab-header {
    padding: 0 25px;
    border-bottom: 1px solid $border-default;
    margin-bottom: 14px;
  }

  & .mat-mdc-tab-body-wrapper {
    height: 100%;
  }

  .mdc-tab__text-label {
    margin-bottom: 8px;
  }

  & .mat-mdc-tab:not(.mdc-tab--active) {
    .mdc-tab__text-label {
      color: $grey-3;
    }
  }

  .mat-mdc-tab.mdc-tab--active {
    .mdc-tab__text-label {
      font-family: 'Roobert-SemiBold', sans-serif;
      color: $grey-2;
    }
  }
}

//TRANSACTION PANEL COMPONENT
.mat-mdc-tab-group.custom-tab-group.transaction-panel-tab-group {
  & .mat-mdc-tab-labels {
    gap: 50px;
  }

  & .mat-mdc-tab {
    min-width: fit-content;
  }

  & .mat-mdc-tab-body-wrapper {
    height: 565px;
  }

  &.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: unset;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-tab-header {
    background: $background-main;
    margin-bottom: 0;
  }
}

//DOCUMENT DIALOG PANEL COMPONENT
.mat-mdc-tab-group.custom-tab-group.documents-dialog-tab-group {
  & .mat-mdc-tab-body-wrapper {
    padding-bottom: 14px;
  }
}

// DASHBOARD SETTINGS DIALOG
.mat-mdc-tab-group.custom-tab-group.dashboard-settings-tab-group {
  & .mat-mdc-tab-labels {
    gap: 24px;
  }

  & .mat-mdc-tab {
    min-width: fit-content;
  }

  &.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: unset;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-tab-header {
    padding: 0 25px;
    margin-bottom: 0;
  }
}
