@import '../../vars.scss';

// Search
.mat-select-search-input {
  border-radius: 7px;
  padding: 0 10px 0 46px !important;
  height: 38px !important;
  margin: 8px 0 0px 0;
  border: none;
  color: $grey-1;
  outline-offset: -1px;
  background:
    url('/assets/img/icons/search-grey.svg') no-repeat 14px 8px,
    $background-main !important;

  &::placeholder {
    color: $grey-3;
  }

  &:hover {
    outline: 1px solid $border-default !important;
    background:
      url('/assets/img/icons/search-grey.svg') no-repeat 14px 8px,
      background-grey !important;
  }

  &:focus-visible {
    outline: 1px solid $secondary-color !important;
    background:
      url('/assets/img/icons/search-active.svg') no-repeat 14px 8px,
      white !important;
  }
}

.mat-select-search-clear {
  display: none !important;
}

// Variant

.custom-select-variant-panel {
  min-width: 443px !important;

  p.body-12-med {
    margin: 0 4px 8px 4px;
    color: $text-grey-3;
  }
}

.mat-mdc-select.custom-select-variant {
  border-radius: 4px;
  padding: 2px 6px;
  width: fit-content;

  .mat-mdc-select-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
  }

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down-active.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-value {
    height: 32px;
    overflow: visible;
  }

  .mat-mdc-select-min-line {
    font-size: 25px;
    color: $primary-color;
  }

  &--active:not(.mat-mdc-select-disabled) {
    background: #dcd8ff;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up-active.svg') no-repeat 0px 9px;
    }
  }
}

// Custom

.mat-mdc-select.custom-select {
  border: 1px solid $border-default;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 5px 14px;

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-value {
    height: 18px;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
  }

  &:hover {
    border: 1px solid $border-color;
  }

  &--active:not(.mat-mdc-select-disabled) {
    border: 1px solid $primary-color !important;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up.svg') no-repeat 0px 9px;
    }
  }

  &:focus {
    border: 1px solid $stroke-focused;
  }
}

.mat-mdc-select.custom-select-language {
  max-width: 85px;
  width: 100%;

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down-black.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-value {
    height: 18px;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 12px;
    color: black;
  }

  &--active:not(.mat-mdc-select-disabled) {
    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up-black.svg') no-repeat 0px 9px;
    }
  }
}

.custom-chart-select-panel {
  max-height: none !important;
}

.mat-mdc-select.custom-chart-select {
  border: 1px solid $border-default;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 2px 10px;

  .mat-mdc-select-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
  }

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    height: 25px;
    background: url('/assets/img/icons/select-arrow-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-value {
    height: 15px;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 15px;
  }

  &:hover {
    border: 1px solid $border-color;
  }

  &--active:not(.mat-mdc-select-disabled) {
    border: 1px solid $primary-color !important;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up.svg') no-repeat 0px 9px;
    }
  }

  &:focus {
    border: 1px solid $stroke-focused;
  }
}

.mat-mdc-select.custom-swapper-select {
  background: transparent;
  border: none;
  border-radius: 26px;
  padding: 4px 12px 2px 4px;
  width: 108px;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
  }

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/swapper-select-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }
}

.custom-swapper-select-panel {
  flex: 1;
  margin-left: 8px !important;
}

.chart-index {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;

  p {
    &.body-14-med {
      color: $grey-1;
    }

    &.body-13 {
      color: $grey-3;
    }
  }
}

.active-chart-index {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;

  &--disabled {
    opacity: 0.5;
  }

  &__caption {
    border-radius: 2px;
    background: #fb7718;
    width: 8px;
    height: 8px;
  }

  p.body-12 {
    color: $grey-3;
  }

  &__eye {
    width: 16px;
    height: 12px;
    background: url('/assets/img/icons/eye.svg') center center no-repeat;

    &::before {
      content: '';
      background: url('/assets/img/icons/eye-hover.svg') no-repeat center center;
      width: 0;
      height: 0;
      position: absolute;
      overflow: hidden; /* Ensure it’s not visible */
    }

    &:hover {
      background: url('/assets/img/icons/eye-hover.svg') center center no-repeat;
    }
  }

  &__eye-close {
    width: 16px;
    height: 12px;
    background: url('/assets/img/icons/eye-close.svg') center center no-repeat;

    &::before {
      content: '';
      background: url('/assets/img/icons/eye-close-hover.svg') no-repeat center center;
      width: 0;
      height: 0;
      position: absolute;
      overflow: hidden; /* Ensure it’s not visible */
    }

    &:hover {
      background: url('/assets/img/icons/eye-close-hover.svg') center center no-repeat;
    }
  }
}

div.mat-mdc-select-panel {
  min-width: 100%;
  margin: 16px 0 0 0;
  padding: 6px !important;
  border-radius: 10px !important;
  border: 1px solid $border-default !important;
  background: #fff !important;
  box-shadow: 0px 10px 13px 0px rgba(17, 16, 33, 0.1) !important;

  .mat-mdc-option.mdc-list-item {
    border-radius: 5px;
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    min-height: 38px;
    &:not(:first-child) {
      margin-top: 2px;
    }

    &:hover {
      background: $background-grey-2;
      color: $grey-1;

      .language-option,
      .platform-option {
        color: $grey-1;

        p.body-12 {
          color: $grey-3;
        }
      }
    }

    &.mat-mdc-option-active {
      color: $grey-1;
      background: #eeebff;

      .language-option,
      .platform-option {
        color: $grey-1;

        p.body-12 {
          color: $grey-3;
        }
      }
    }

    & .mdc-list-item__primary-text {
      color: $grey-1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 8px;
    }
  }
}

.language-option,
.platform-option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 12px;
}

.mat-pseudo-checkbox-full {
  border-radius: 6px !important;
  border: 1px solid #808594 !important;
}

// Subtype

.subtype-select-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 4px;
  height: 22px;
  width: fit-content;
  border-radius: 4px;
  padding: 6px 4px;
  background: white;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .icon {
    width: 8px;
    height: 8px;
    visibility: hidden;
    background: url('/assets/img/icons/arrow-down.svg') no-repeat center;
  }

  &:hover {
    outline: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.1) !important;

    .icon {
      visibility: visible;
    }
  }
}

// Warnings
.mat-mdc-select.custom-select-warnings {
  border: 1px solid $border-default;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 5px 14px;

  .mat-mdc-select-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
  }

  .mat-mdc-select-placeholder {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    color: $grey-2;
  }

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
  }

  &:hover {
    border: 1px solid $border-color;
  }

  &--active:not(.mat-mdc-select-disabled) {
    border: 1px solid $primary-color !important;
    outline: 3px solid $ternary-color !important;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up.svg') no-repeat 0px 9px;
    }
  }

  &:focus {
    border: 1px solid $stroke-focused;
    outline: 3px solid $stroke-outline;
  }
}

.mat-mdc-select.custom-token-select {
  border: 1px solid $border-default;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 5px 14px;

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
  }

  &:hover {
    border: 1px solid $border-color;
  }

  &--active:not(.mat-mdc-select-disabled) {
    border: 1px solid $primary-color !important;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up.svg') no-repeat 0px 9px;
    }
  }

  &:focus {
    border: 1px solid $stroke-focused;
  }
}
