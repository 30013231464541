@import '../../vars.scss';

.mat-mdc-snack-bar-container.custom-toast {
  margin: 8px 8px 35px 8px;

  .mdc-snackbar__label {
    padding: 0;
  }

  .mdc-snackbar__surface {
    min-width: 0;
    border-radius: 7px;
    box-shadow: 0px 10px 18px 0px rgba(17, 16, 33, 0.18);
    background: $background-tooltip;
    padding: 14px;
  }
}
