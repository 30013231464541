@import '../../vars.scss';

.mat-mdc-slide-toggle.custom-slide-toggle {
  // Off
  --mdc-switch-unselected-track-color: #e6e8ed;
  --mdc-switch-unselected-hover-track-color: #e6e8ed;
  --mdc-switch-disabled-selected-track-color: #e6e8ed;
  --mdc-switch-disabled-unselected-track-color: #e6e8ed;
  --mdc-switch-unselected-focus-track-color: #e6e8ed;
  --mdc-switch-unselected-pressed-track-color: #e6e8ed;

  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-unselected-hover-handle-color: white;
  --mdc-switch-disabled-selected-handle-color: white;
  --mdc-switch-disabled-unselected-handle-color: white;
  --mdc-switch-unselected-focus-handle-color: white;
  --mdc-switch-unselected-pressed-handle-color: white;

  .mdc-form-field > label {
    padding-left: 17px;
  }

  // On
  &.mat-primary {
    --mdc-switch-selected-track-color: #503bff;
    --mdc-switch-selected-focus-track-color: #503bff;
    --mdc-switch-selected-hover-track-color: #503bff;
    --mdc-switch-selected-pressed-track-color: #503bff;

    --mdc-switch-selected-handle-color: white;
    --mdc-switch-selected-focus-handle-color: white;
    --mdc-switch-selected-hover-handle-color: white;
    --mdc-switch-selected-pressed-handle-color: white;
  }

  .mdc-switch {
    width: 41px;

    .mdc-switch__handle {
      width: 21px;
      height: 21px;
      border: 1px solid #cfd1d5;
    }

    .mdc-switch__track {
      height: 22px;
      border: 1px solid #d4d8e1;
      border-radius: 13px;
    }

    &--checked {
      .mdc-switch__handle {
        border: 1px solid #4735e6;
      }

      .mdc-switch__track {
        border: 1px solid #4735e6;
      }
    }
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch__ripple {
    display: none;
  }
}

.mat-mdc-slide-toggle.custom-token-slide-toggle {
  pointer-events: unset !important;
  user-select: unset !important;

  // Off
  --mdc-switch-unselected-track-color: #e6e8ed;
  --mdc-switch-unselected-hover-track-color: #e6e8ed;
  --mdc-switch-disabled-selected-track-color: #503bff;
  --mdc-switch-disabled-unselected-track-color: #e6e8ed;
  --mdc-switch-unselected-focus-track-color: #e6e8ed;
  --mdc-switch-unselected-pressed-track-color: #e6e8ed;

  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-unselected-hover-handle-color: white;
  --mdc-switch-disabled-selected-handle-color: white;
  --mdc-switch-disabled-unselected-handle-color: white;
  --mdc-switch-unselected-focus-handle-color: white;
  --mdc-switch-unselected-pressed-handle-color: white;

  --mdc-switch-disabled-track-opacity: 1;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 1;

  // On
  &.mat-primary {
    --mdc-switch-selected-track-color: #503bff;
    --mdc-switch-selected-focus-track-color: #503bff;
    --mdc-switch-selected-hover-track-color: #503bff;
    --mdc-switch-selected-pressed-track-color: #503bff;

    --mdc-switch-selected-handle-color: white;
    --mdc-switch-selected-focus-handle-color: white;
    --mdc-switch-selected-hover-handle-color: white;
    --mdc-switch-selected-pressed-handle-color: white;
  }

  .mdc-switch {
    width: 41px;

    .mdc-switch__handle {
      width: 21px;
      height: 21px;
      border: 1px solid #cfd1d5;
      pointer-events: unset !important;
      cursor: pointer !important;
    }

    .mdc-switch__handle-track {
      pointer-events: unset !important;
      cursor: pointer !important;
    }

    .mdc-switch__track {
      height: 22px;
      border: 1px solid #d4d8e1;
      border-radius: 13px;
    }

    &--checked {
      .mdc-switch__handle {
        border: 1px solid #4735e6;
      }

      .mdc-switch__track {
        border: 1px solid #4735e6;
      }
    }
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch__ripple {
    display: none;
  }
}
