.mat-sort-header.custom-sort-header {
  .mat-sort-header-container {
    .mat-sort-header-arrow {
      // ! Importants are required here because material overrides these styles directly in style attribute of element
      opacity: 0.54 !important;
      transform: translateY(0px) !important;
    }
  }

  &--start {
    .mat-sort-header-container {
      justify-content: flex-start;
    }
  }

  &--end {
    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }
}
