@font-face {
  font-family: 'Roobert';
  src: url('./Roobert-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roobert-Bold';
  src: url('./Roobert-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roobert-Heavy';
  src: url('./Roobert-Heavy.woff2') format('woff2');
}

@font-face {
  font-family: 'Roobert-Light';
  src: url('./Roobert-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Roobert-Medium';
  src: url('./Roobert-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Roobert-Regular';
  src: url('./Roobert-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roobert-SemiBold';
  src: url('./Roobert-SemiBold.woff2') format('woff2');
}
