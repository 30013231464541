.mat-mdc-card-header {
  padding: 16px !important;
}

.mat-mdc-card.custom-card {
  border: 1px solid #eceff1;
  box-shadow: 0px 1px 2px rgba(5, 7, 10, 0.08);

  .mat-mdc-card-header {
    padding: 32px 24px;
  }

  .mat-mdc-card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #05070a;
    margin: 0;
  }

  .mat-mdc-card-content {
    padding: 24px;
  }
}
