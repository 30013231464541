@import '../../vars.scss';

.mat-mdc-progress-bar.custom-progress-bar {
  .mdc-linear-progress__buffer-dots {
    animation-direction: reverse;
  }
}

// Allocation
.mat-mdc-progress-bar.allocation-bar {
  border-radius: 20px;

  .mdc-linear-progress__buffer-bar {
    background-color: #ededed;
  }
}

.mat-mdc-progress-bar.onboarding-bar {
  border-radius: 2px;

  --mdc-linear-progress-track-color: rgba(0, 0, 0, 0.2);
}

.mat-mdc-progress-bar {
  &.mat-success {
    --mdc-linear-progress-active-indicator-color: #00b071;
  }
}
