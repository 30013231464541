.mat-mdc-menu-panel.custom-menu {
  border-radius: 10px;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  width: 221px;
  margin: 0 0 14px 14px;
  box-shadow: 0px 10px 13px 0px rgba(17, 16, 33, 0.1) !important;

  &.no-width {
    width: auto;
  }

  .mat-mdc-menu-content {
    padding: 0;
  }

  .menu {
    display: flex;
    flex-direction: column;
    padding: 6px;

    .item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      border-radius: 5px;
      color: $grey-2;
      padding: 14px;
      column-gap: 4px;

      &--advanced {
        justify-content: space-between;
        padding: 14px;
      }

      &__options {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 4px;

        p.body-14-med {
          color: $grey-3;
        }
      }

      &:hover:not(.item--warn) {
        background: $background-main;

        p {
          color: $grey-1;
        }

        p.item__details {
          color: $grey-3;
        }
      }

      .sponsorship-chip {
        background: $background-success;
        color: $text-success;
        padding: 1px 4px;
        font-size: 12px;
        border-radius: 3px;
      }

      &--locked {
        cursor: default;

        & > p {
          opacity: 40%;
        }
      }

      &--warn {
        justify-content: space-between;
        background: $background-error-label;
      }

      &__details {
        color: $grey-3;
      }
    }

    .action {
      cursor: pointer;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 11px;

      p {
        color: $grey-3;
      }

      &__icon-logout {
        width: 20px;
        height: 20px;
        background: url('/assets/img/icons/logout.svg') no-repeat center center;

        &::before {
          content: '';
          background: url('/assets/img/icons/logout-active.svg') no-repeat center center;
          width: 0;
          height: 0;
          position: absolute;
          overflow: hidden; /* Ensure it’s not visible */
        }
      }

      &__icon-delete {
        width: 20px;
        height: 20px;
        background: url('/assets/img/icons/delete.svg') no-repeat center center;

        &::before {
          content: '';
          background: url('/assets/img/icons/delete-active.svg') no-repeat center center;
          width: 0;
          height: 0;
          position: absolute;
          overflow: hidden; /* Ensure it’s not visible */
        }
      }

      &:hover {
        background: $background-error;
        p {
          color: $text-error-label;
        }

        .action__icon-logout {
          background: url('/assets/img/icons/logout-active.svg') no-repeat center center;
        }

        .action__icon-delete {
          background: url('/assets/img/icons/delete-active.svg') no-repeat center center;
        }
      }
    }

    &--settings {
      padding: 0;
      gap: 2px;

      .item {
        margin: 0 6px 6px 6px;

        &:first-child {
          margin: 6px 6px 0 6px;
        }

        &--margin {
          margin: 6px;
        }
      }
    }
  }
}

.custom-menu.documents {
  position: absolute;
  left: 105px;
  bottom: -90px;
  min-width: 0;
  width: 95px;
}
