@import '../../vars.scss';

.mat-mdc-paginator.custom-paginator {
  border-top: 1px solid rgba(224, 225, 226, 0.7);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  .mat-mdc-paginator-container {
    justify-content: space-between;
    height: 87px;

    .mat-mdc-paginator-range-actions {
      &:first-child > span {
        margin-right: auto;
      }

      &:last-child > span {
        margin-left: auto;
      }

      .mat-mdc-paginator-range-label {
        display: none;
      }
    }
  }
}

.mat-mdc-paginator.waltio-paginator {
  background: none;

  .mat-mdc-paginator-container {
    height: 32px;
    min-height: 0;
    padding: 0;

    .mat-mdc-paginator-range-actions {
      column-gap: 4px;

      .mat-mdc-paginator-range-label {
        color: $grey-3;
        margin: 0;
      }

      .mat-mdc-paginator-icon {
        --mdc-icon-button-icon-size: 20px;
        --mat-paginator-enabled-icon-color: #3b414f;
        --mat-paginator-disabled-icon-color: #808594;
      }

      button {
        border-radius: 5px;
        padding: 6px;
        width: 32px;
        height: 32px;

        &:hover {
          background: #f1efff;

          .mat-mdc-paginator-icon {
            fill: $primary-color;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}
