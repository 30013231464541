@import '../../vars.scss';

input[matinput].custom-account-input {
  border-radius: 4px;
  background: white;
  border: 1px solid $border-default;
  padding: 4px 6px;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.15);
  font-family: 'Roobert-Medium', sans-serif;
  color: $grey-1;

  &:focus {
    outline: none;
  }
}

input[matinput].custom-input {
  width: 100%;
  font-family: 'Roobert-Medium', sans-serif;
  border-radius: 7px;
  background: white;
  border: 1px solid $border-default;
  padding: 7.2px 14px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  color: $grey-1;

  &::placeholder {
    color: $grey-3;
  }

  &:disabled {
    color: $grey-3;
    background: $background-main;
    border: 1px solid $border-default;
  }

  &:hover:not(:disabled) {
    border: 1px solid $border-color !important;
    background: white;
  }

  &:focus {
    border: 1px solid $stroke-focused;
    outline: 3px solid $stroke-outline;
    background: white;
    color: $grey-2;
  }

  &.currency-input {
    padding-left: 32px;
  }

  &--active {
    color: $grey-1;
    border: 1px solid $primary-color !important;
    outline: 3px solid $ternary-color !important;
    background: white;
    pointer-events: none;
  }

  &--error {
    color: $grey-1;
    border: 1px solid #e3535c;
    background: $background-error;
  }

  &--search {
    padding: 14px 14px 14px 45px;
    background:
      url('/assets/img/icons/search.svg') no-repeat 14px 11px,
      white;

    &:hover:not(:disabled) {
      background:
        url('/assets/img/icons/search.svg') no-repeat 14px 11px,
        white;
    }

    &:focus {
      background:
        url('/assets/img/icons/search.svg') no-repeat 14px 11px,
        white;
    }
  }
}

input[matinput].custom-swapper-input {
  width: 100%;
  background: transparent;
  border: none;
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 26px;
  color: $grey-2;

  &::placeholder {
    opacity: 0.4;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.input-error {
  margin-top: 10px !important;
  color: $warn-color;
  font-size: 12px;
}

.searchbar {
  width: 100%;
  padding: 12px 0 12px 57px;
  border-radius: 50px;
  background:
    url('/assets/img/icons/search.svg') no-repeat 21px 11px,
    $background-grey-2;
  border: 1px solid transparent;

  &::placeholder {
    color: $grey-3;
  }

  &:hover {
    outline: 1px solid $border-color;
    background:
      url('/assets/img/icons/search.svg') no-repeat 21px 11px,
      $background-grey-3;
  }

  &:focus-visible {
    border: 1px solid transparent;
    outline: 3px solid transparent;
    background:
      url('/assets/img/icons/search.svg') no-repeat 21px 11px,
      $background-grey-3;
    color: $grey-1;
  }

  &--focus {
    &:focus-visible {
      border: 1px solid $primary-color;
      outline: 3px solid $ternary-color;
      background:
        url('/assets/img/icons/search-active.svg') no-repeat 21px 11px,
        white;
      color: $grey-1;
    }
  }
}

.swapper-searchbar {
  width: 100%;
  padding: 10px 0 10px 46px;
  border-radius: 7px;
  margin-bottom: 4px;
  background:
    url('/assets/img/icons/search.svg') no-repeat 14px 8px,
    $background-main;
  border: 1px solid transparent;

  &::placeholder {
    color: $grey-3;
  }

  &:hover {
    outline: 1px solid $border-color;
    background:
      url('/assets/img/icons/search.svg') no-repeat 14px 8px,
      $background-grey-3;
  }

  &:focus-visible {
    border: 1px solid $primary-color;
    outline: 3px solid $ternary-color;
    background:
      url('/assets/img/icons/search-active.svg') no-repeat 14px 8px,
      white;
    color: $grey-1;
  }
}

.subtype-searchbar {
  width: 338px;
  padding: 8px 0 8px 36px;
  border-radius: 50px;
  background:
    url('/assets/img/icons/search.svg') no-repeat 10px 7px,
    white;
  border: 1px solid $border-color;

  &::placeholder {
    color: $grey-3;
  }

  &:focus-visible {
    border: 1px solid $primary-color;
    outline: 3px solid $ternary-color;
    background:
      url('/assets/img/icons/search.svg') no-repeat 10px 7px,
      white;
    color: $grey-1;
  }
}

.token-input-container {
  position: relative;
}
