@import '../../vars.scss';

table {
  width: 100%;
}

.mat-mdc-no-data-row {
  .mat-mdc-cell {
    border: none;
    padding: 0 20px 0 26px;
  }
}

// Tokens table
.mat-mdc-table.tokens-table {
  --mat-table-row-item-container-height: 58px;

  .mat-mdc-header-cell {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 12px;
    color: $grey-3;

    border-bottom: none;

    &:last-child {
      padding: 0 52px 0 16px;
    }
  }

  .mdc-data-table__content {
    .spacer {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: none;
      }
    }
  }

  .mat-mdc-cell {
    border-bottom: none;
  }

  .mat-column-price {
    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }

  .mat-column-amount,
  .mat-column-pnl {
    min-width: 15%;
    text-align: right;
  }

  tr.token-row {
    .mdc-data-table__cell:first-child {
      border-radius: 6px 0 0 6px;
      padding: 0 12px;
    }

    .mdc-data-table__cell:last-child {
      border-radius: 0 6px 6px 0;
      padding: 0 18px;
    }
  }

  tr.token-breakdown-row {
    .mdc-data-table__cell {
      padding: 0;
    }
  }
}

// Loans
.mat-mdc-table.loans-table {
  --mat-table-row-item-container-height: 58px;

  .mat-mdc-header-cell {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 12px;
    color: $grey-3;

    border-bottom: none;
  }

  .mdc-data-table__content {
    .spacer {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: none;
      }
    }
  }

  .mat-mdc-cell {
    border-bottom: none;
  }

  .mat-column-name {
    width: 40%;
  }

  .mat-column-price {
    width: 15%;
  }

  .mat-column-trendDay {
    width: 15%;
  }

  .mat-column-trendWeek {
    width: 15%;
  }

  .mat-column-value {
    text-align: right;
  }

  tr.loan-row {
    .mdc-data-table__cell:first-child {
      border-radius: 6px 0 0 6px;
      padding: 0 12px;
    }

    .mdc-data-table__cell:last-child {
      border-radius: 0 6px 6px 0;
      padding: 0 18px;
    }
  }

  tr.loan-breakdown-row {
    .mdc-data-table__cell {
      padding: 0;
    }
  }
}

// Scam table
.mat-mdc-table.scams-table {
  .mat-mdc-header-cell,
  .mat-mdc-cell {
    border-bottom: none;
  }

  .mdc-data-table__cell:first-child {
    padding: 0 16px 0 0;
  }

  .mdc-data-table__cell:last-child {
    padding: 0 0 0 16px;
  }

  .mat-column-actions {
    text-align: right;
  }
}

// Platforms table
.mat-mdc-table.platforms-table {
  --mat-table-row-item-container-height: 58px;

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    border-bottom: none;
  }

  .mdc-data-table__content {
    .spacer {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: none;
      }
    }
  }

  .mat-column-value {
    text-align: right;
  }

  tr.platform-row {
    .mdc-data-table__cell:first-child {
      border-radius: 6px 0 0 6px;
      padding: 0 12px;
    }

    .mdc-data-table__cell:last-child {
      border-radius: 0 6px 6px 0;
      padding: 0 18px;
    }
  }

  tr.platform-breakdown-row {
    .mdc-data-table__cell {
      padding: 0;
    }
  }
}

// Protocols table
.mat-mdc-table.protocols-table {
  --mat-table-row-item-container-height: 58px;

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    border-bottom: none;
  }

  .mdc-data-table__content {
    .spacer {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: none;
      }
    }
  }

  .mat-column-value {
    text-align: right;
  }

  tr.protocol-row {
    .mdc-data-table__cell:first-child {
      border-radius: 6px 0 0 6px;
      padding: 0 12px;
    }

    .mdc-data-table__cell:last-child {
      border-radius: 0 6px 6px 0;
      padding: 0 18px;
    }
  }

  tr.protocol-breakdown-row {
    .mdc-data-table__cell {
      padding: 0;
    }
  }
}

// Transactions table
.mat-mdc-table.transactions-table {
  --mat-table-header-container-height: 41px;
  --mat-table-row-item-container-height: 62px;

  .mat-mdc-row .mat-mdc-cell {
    border-bottom: 1px solid #e0e0e0;
  }

  .mdc-data-table__cell:not(:first-child):not(:last-child),
  .mdc-data-table__header-cell:not(:first-child):not(:last-child) {
    padding: 0 6px;
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 20px 0 26px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 26px 0 20px;
  }

  .mat-mdc-header-cell {
    font-family: 'Roobert', sans-serif;
    font-size: 13px;
    color: $grey-3;
  }

  .mat-column-select {
    width: 70px;
  }

  .mat-column-type {
    .transaction-type {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 6px;

      app-subtype-select {
        position: absolute;
        margin-left: 40px;
        z-index: 9999;
      }

      &--trade {
        img:first-child {
          margin-right: 4px;
        }
      }
    }
  }

  .mat-column-transactionDate {
    width: 20%;

    p.body-12-med,
    p.body-12 {
      color: $grey-3;
    }

    p.body-12 {
      margin-top: 8px;
    }
  }

  .mat-column-platform {
    p.body-12-med {
      color: $grey-3;
    }
  }

  .mat-column-fromAmount {
    padding: 0 0 0 6px !important;
    width: 18%;
  }

  .mat-column-trade {
    width: 4%;
    padding: 0 !important;
  }

  .mat-column-toAmount {
    padding: 0 6px 0 0 !important;
    width: 18%;
  }

  .mat-column-transferPrice,
  .mat-column-actions {
    text-align: right;

    p {
      text-wrap: nowrap;
    }
  }

  .mat-column-quantity {
    .quantity {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 6px;

      p.body-14-med {
        color: $grey-3;
      }

      p.body-14-bold {
        color: $grey-1;
      }
    }
  }

  .mat-column-price,
  .mat-column-buyingCost,
  .mat-column-sellingCost,
  .mat-column-pnl {
    p.body-14-med {
      color: $grey-3;
    }

    p.body-14-bold {
      color: $grey-1;
    }
  }

  .mat-mdc-row {
    &:hover:not(.cost-basis) {
      background: #f7f6ff;

      .subtype-select-btn {
        background: rgba(0, 0, 0, 0.06);

        .icon {
          visibility: visible;
        }
      }
    }

    &.disable-hover:hover {
      background: none;
      cursor: default;
    }
  }

  &.sticky {
    .mat-mdc-header-cell {
      top: 111px !important;
    }
  }
}

.mat-mdc-table.transactions-creator-table {
  --mat-table-header-container-height: 41px;
  --mat-table-row-item-container-height: 60px;

  .mdc-data-table__cell:not(:first-child):not(:last-child),
  .mdc-data-table__header-cell:not(:first-child):not(:last-child) {
    padding: 0 6px;
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 20px 0 26px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 26px 0 20px;
  }

  .mat-mdc-header-cell {
    font-family: 'Roobert', sans-serif;
    font-size: 13px;
    color: $grey-3;
  }

  .mat-column-type {
    min-width: 175px;

    .transaction-type {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 6px;

      app-subtype-select {
        position: absolute;
        margin-left: 40px;
        z-index: 9999;
      }

      &--trade {
        img:first-child {
          margin-right: 4px;
        }
      }
    }
  }

  .mat-column-platform {
    min-width: 120px;
  }

  .mat-column-transactionDate {
    min-width: 250px;
  }

  .mat-column-fromAmount {
    min-width: 250px;
  }

  .mat-column-toAmount {
    min-width: 250px;
  }

  .mat-column-actions {
    text-align: right;

    p {
      text-wrap: nowrap;
    }
  }
}

.mat-mdc-table.accounts-table {
  --mat-table-header-container-height: 38px;
  --mat-table-row-item-container-height: 74px;

  background: white;
  border: 1px solid #e0e0e0;
  border-spacing: 0 4px;
  border-radius: 7px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);

  .mat-mdc-header-cell {
    font-family: 'Roobert-SemiBold', sans-serif;
    font-size: 12px;
    color: $grey-2;
  }

  .mdc-data-table__header-cell:first-child,
  .mdc-data-table__cell:first-child {
    padding: 0 16px 0 25px;
  }

  .mdc-data-table__cell:last-child {
    padding: 0 25px 0 16px;
  }

  .mdc-data-table__header-cell:last-child {
    padding: 0 72px 0 16px;
  }

  .mat-mdc-cell {
    background: unset;
    overflow: visible;
  }

  .mat-column-platform {
    width: 25%;

    .name {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;
    }

    .account-infos {
      display: flex;
      flex-flow: column nowrap;
      row-gap: 2px;

      &__name {
        padding: 4px 6px;
        border-radius: 4px;
        width: fit-content;

        &:hover {
          background: $background-grey;
        }
      }

      &__name--default {
        padding: 4px 6px;
        border-radius: 4px;
      }

      p.body-14-med {
        color: $grey-1;
      }

      p.body-13 {
        padding-left: 6px;
        color: $grey-3;
      }
    }
  }

  .mat-column-actions,
  .mat-column-status {
    text-align: end;

    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }
}

.mat-mdc-table.unknown-prices-table {
  --mat-table-header-container-height: 46px;
  --mat-table-row-item-container-height: 54px;

  background: white;
  border: 1px solid #e0e0e0;

  .mdc-data-table__cell:nth-child(2),
  .mdc-data-table__header-cell:nth-child(2) {
    padding: 0 16px 0 0;
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 10px 0 6px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 26px 0 20px;

    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }

  .mat-mdc-header-cell {
    font-family: 'Roobert', sans-serif;
    font-size: 13px;
    color: $grey-3;
  }

  .mat-column-bar {
    width: 5px;

    .bar {
      width: 5px;
      border-radius: 10px;
      background: #f7931a;
      height: 41px;

      &--success {
        background: $success-color;
      }
    }
  }

  .mat-column-token {
    .token {
      img {
        margin-right: 5px;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 7px;

      p.body-14 {
        color: $grey-3;
      }
    }
  }

  .mat-column-price {
    .price {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 6px;
      padding: 2px 6px;
      width: fit-content;
    }

    .price-warning {
      border-radius: 4px;
      border: 1px solid rgba(195, 79, 0, 0.1);
      background: $background-warning;
    }
  }

  .mat-column-balance {
    text-align: right;
  }

  .mat-mdc-row {
    &:hover {
      background: #f7f6ff;
    }
  }

  .scam-token {
    .price,
    .volume,
    .balance {
      opacity: 0.5;
    }

    .token img {
      opacity: 0.5;
    }
  }
}

// Tokens table
.mat-mdc-table.platforms-fees-table {
  --mat-table-header-container-height: 46px;
  --mat-table-row-item-container-height: 48px;

  border-radius: 6px;
  border-collapse: separate;
  border-spacing: 0 4px;

  .mat-mdc-header-cell {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 12px;
    color: $grey-3;

    &.mat-column-waltioAverageFees,
    &.mat-column-averageFees {
      text-decoration: underline;
      text-underline-offset: 2px;

      .f-row-gap {
        justify-content: flex-end;
      }
    }
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 16px 0 16px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 16px 0 16px;
  }

  .mat-mdc-cell {
    border-bottom: none;
  }

  .mat-column-fees {
    p.body-14 {
      width: fit-content;
      color: $grey-2;
    }
  }

  .mat-column-waltioAverageFees {
    text-align: right;

    .f-row-gap {
      justify-content: flex-end;
    }
  }

  .mat-column-averageFees {
    text-align: right;

    .f-row-gap {
      justify-content: flex-end;
    }
  }

  .mat-column-instruction {
    min-width: 120px;
  }
}

.mat-mdc-table.positions-table {
  --mat-table-header-container-height: 46px;
  --mat-table-row-item-container-height: 52px;
  border-spacing: 0 4px;
  border-radius: 6px;

  .mat-mdc-cell {
    border-bottom: none;
  }

  .mat-mdc-header-cell {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 12px;
    color: $grey-3;
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 16px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 16px;
  }

  .mat-column-actions,
  .mat-column-estimatedReward {
    text-align: right;

    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }

  .platform {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;

    p.body-14-med {
      color: $grey-2;
    }
  }
}
