@import '../../vars.scss';

.mat-mdc-chip-listbox.custom-chip-listbox {
  .custom-chip {
    &--selected {
      background: $primary-color !important;

      .mdc-evolution-chip__text-label {
        color: white !important;
      }
    }
  }

  .mat-mdc-standard-chip {
    border-radius: 24px;
    background: transparent;
  }

  .mat-mdc-chip-selected {
    background: $primary-color;
    color: white;

    .sponsorship-gain {
      background: white;

      p {
        color: $primary-color;
      }
    }
  }

  .mat-mdc-chip-option:not(.mat-mdc-chip-selected):hover {
    background: $background-grey-2;
  }

  .mdc-evolution-chip__text-label {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
  }

  .mdc-evolution-chip-set__chips {
    flex-flow: row nowrap;
  }
}
