.mat-expansion-panel.custom-expansion-panel {
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: visible;

  &:not([class*='mat-elevation-z']) {
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  }

  .mat-expansion-panel-header {
    height: 74px;
    padding: 0 25px;

    .mat-expansion-panel-header-title {
      margin: 0;
      justify-content: space-between;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-indicator {
    margin-bottom: 8px;
  }

  .mat-content.mat-content-hide-toggle {
    margin: 0;
  }
}
