.mat-mdc-tooltip {
  white-space: pre-line;

  .mdc-tooltip__surface {
    max-width: 300px;
    text-align: left;
    background: #111021 !important;
    padding: 4px 6px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15) !important;
    color: white !important;
  }
}
